import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window) {
  var $survey, $polls, $responsive_container;
  var surveyStatus = false;
  var initSurvey = function initSurvey() {
    $survey = $('#survey');
    if ($survey.length == 0) return false;
    $polls = $('#survey .poll-slide');
    $polls.on('click', '.btn-poll', movePoll);
    $polls.on('click', '.poll-single-value .poll-option', selectSingleValue);
    $polls.on('click', '.poll-single-option .poll-option', selectSingleOption);
    $polls.on('click', '.poll-multiple-options .poll-option', selectOption);
    $polls.on('click', '.poll-single-option .add-comment', showComment);
    return true;
  };
  var showComment = function showComment() {
    var btn = $(this);
    var target = $(btn.data('target'));
    console.log('targettargettarget', target);
    if (btn.hasClass('collapsed')) {
      target.slideDown(function () {
        btn.removeClass('collapsed');
      });
    } else {
      target.slideUp(function () {
        btn.addClass('collapsed');
      });
    }
  };
  var movePoll = function movePoll() {
    var $btn = $(this);
    var $poll = $btn.parents('.poll');
    var pos = $poll.data('slide');
    var dir = $btn.hasClass('btn-prev') ? -1 : 1;
    var toSlide = pos + dir;
    if (!checkFreeField($poll)) {
      return false;
    }
    if (toSlide > 0 && toSlide < $polls.length + 1) {
      $survey.find('.poll.active').removeClass('active');
      var pollNow = $survey.find('.poll[data-slide="' + toSlide + '"]');
      pollNow.addClass('active');
      console.log('minminminmin', pollNow.find('.poll-options').data('min'));
      console.log('minminminminminsssss', pollNow.data('slide'));
      if (pollNow.find('.poll-multiple-options').length) {
        updateNextButton(pollNow, pollNow.find('.poll-options').data('min'));
      }
    } else if (toSlide >= $polls.length) {
      $survey.find('.poll.active').removeClass('active');
      $survey.find('.poll-sending').addClass('active');
      var results = getPollResults();

      //console.log('result', $results);

      sendPoll(results);
    } else {

      //console.log('donothing!!!!')
    }
    surveyResize();
  };
  var sendPoll = function sendPoll(results) {
    console.log('sending survey results', results);
    $.ajax({
      type: "POST",
      async: true,
      url: window.survey_url,
      cache: false,
      data: {
        value: JSON.stringify(results)
      }
    }).done(function () {
      setTimeout(function () {
        $survey.find('.poll-sending').removeClass('active');
        $survey.find('.poll-outro').addClass('active');
      }, 1500);
    }).fail(function (response) {
      console.error(response);
    });
  };
  var checkFreeField = function checkFreeField($poll) {
    var $freeField = $poll.find('.free-option-value');
    var isFreeOptionSelected = $poll.find('.poll-option.active[data-free="1"]').length;
    if ($freeField.length && isFreeOptionSelected) {
      var isFilled = $freeField.val();
      if (isFilled.length < 2) {
        alert('Please fill in the field');
        return false;
      }
    }
    return true;
  };
  var selectSingleValue = function selectSingleValue(e) {
    var $btn = $(e.target).hasClass('poll-option') ? $(e.target) : $(e.target).parents('.poll-option');
    if ($btn.hasClass('active')) return;

    //var fill = true;

    var value = parseInt($btn.data('value'));
    var $poll = $btn.parents('.poll');
    var $options = $btn.parents('.poll-options');
    $options.find('.active').removeClass('active');
    $btn.addClass('active');
    $options.find('.poll-option').each(function () {
      var $op = $(this);
      var val = parseInt($op.data('value'));
      if (val < value) {

        //$op.addClass('fill')
      } else {
        $op.removeClass('fill');
      }
    });

    //console.log('option selected', value)

    updateNextButton($poll);
  };
  var selectSingleOption = function selectSingleOption(e) {
    var $btn = $(e.target).hasClass('poll-option') ? $(e.target) : $(e.target).parents('.poll-option');
    if ($btn.hasClass('active')) return;
    var $poll = $btn.parents('.poll');
    var $options = $btn.parents('.poll-options');
    $options.find('.active').removeClass('active');
    $btn.addClass('active');
    updateNextButton($poll);
  };
  var selectOption = function selectOption(e) {
    var $btn = $(e.target).hasClass('poll-option') ? $(e.target) : $(e.target).parents('.poll-option');
    var $poll = $btn.parents('.poll');
    var $options = $btn.parents('.poll-options');
    var selected = $options.find('.active').length;
    var max = $options.data('max');
    var min = $options.data('min');
    if ($btn.hasClass('active')) {
      // Se input vale solo come selezione
      if ($(e.target).hasClass('free-option-value')) {
        return;
      }
      $btn.removeClass('active');
      updateNextButton($poll, min);
      return false;
    }
    if (selected >= max) {
      alert("Please choose maximum ".concat(max, " questions"));
      return false;
    }
    $btn.addClass('active');
    updateNextButton($poll, min);
  };
  var updateNextButton = function updateNextButton($poll, min) {
    min = min == undefined ? 0 : min;
    if ($poll.find('.poll-option.active').length >= min) {
      /// controllo campo free
      $poll.find('.btn-next').removeAttr('disabled');
    } else {
      $poll.find('.btn-next').attr('disabled', 'disabled');
    }
  };
  var getPollResults = function getPollResults() {
    var results = [];
    var comment,
      comments = [];
    $polls.each(function () {
      var $poll = $(this);
      var $pollBody = $poll.find('.poll-body');
      var value = '***ERROR***';
      if ($pollBody.hasClass('poll-single-value') || $pollBody.hasClass('poll-single-option')) {
        value = $pollBody.find('.poll-option.active').data('value');
      } else if ($pollBody.hasClass('poll-multiple-options')) {
        var $selected = $pollBody.find('.poll-option.active');
        value = [];
        $selected.each(function () {
          var $op = $(this);
          var val = $op.data('value');
          if ($op.data('free')) {
            val += ' ' + $op.find('.free-option-value').val();
          }
          value.push(val);
        });
      }
      comment = $pollBody.find('.comment');
      if (comment.length && comment.val()) {
        comments.push(comment.val());
      } else {
        comments.push('');
      }
      results.push(value);
    });
    results.push(comments);
    return results;
  };
  var showSurvey = function showSurvey() {
    var $poll = $survey.find('.poll.active');
    if ($poll.find('.poll-multiple-options').length) {
      updateNextButton($poll, $poll.find('.poll-options').data('min'));
    }
    window.onresize = function () {
      surveyResize();
    };
    $('#chat-container').addClass('d-none no-resize');
    $survey.removeClass('d-none no-resize');
    setTimeout(function () {
      surveyResize();
    }, 300);
  };
  var surveyResize = function surveyResize() {
    var vp = getViewport();
    var h = 'auto';
    var poll = $('.poll.poll-slide.active');
    if (vp.x >= 992) {
      if ($('body').hasClass('ie')) {
        h = $responsive_container.outerHeight();
        h = h + 200;
      } else {
        h = '75vh';
      }
    } else {
      h = poll.length && (poll.data('slide') == '1' || poll.data('slide') == '7') ? 'auto' : 700;
    }

    // if ( $aside.length ) {

    //     $aside.height( h );

    // }

    $survey.height(h);
  };
  var startSurvey = function startSurvey() {
    if (!surveyStatus) {
      surveyStatus = initSurvey();
    }

    // visualizza survey
    showSurvey();
  };
  var getViewport = function getViewport() {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    return {
      x: x,
      y: y
    };
  };
  var initPollBoxSurvey = function initPollBoxSurvey() {
    $survey = $('#poll-box');
    if ($survey.length == 0) return false;
    var ratings = $('.rating');
    var poll_options = $('.poll-options');
    var poll_texts = $('.poll-textarea');
    var poll_sending = $('.poll-sending');
    var poll_intro = $('.poll-intro');
    var poll_outro = $('.poll-outro');
    var pollNum = 1;
    window.startPoll = function () {
      if (poll_intro.length) poll_intro.hide();
      $('.poll-' + pollNum).removeClass('d-none');
    };
    $('#poll-box .prev-btn').click(function () {
      $('.poll-' + pollNum).addClass('d-none');
      pollNum--;
      $('.poll-' + pollNum).removeClass('d-none');
    });
    $('#poll-box .next-btn').click(function () {
      $('.poll-' + pollNum).addClass('d-none');
      pollNum++;
      if ($('.poll-' + pollNum).length) {
        $('.poll-' + pollNum).removeClass('d-none');
      }
    });
    poll_texts.each(function () {
      var $box = $(this);
      var $textarea = $box.find('textarea');
      var $next = $box.parents('.poll').find('button.next-btn');
      $textarea.on('keydown', function () {
        $next.removeAttr('disabled');
        $textarea.off('keydown');
      });
    });
    poll_options.each(function () {
      var $box = $(this);
      var $selected_option = $box.find('.selected-option');
      var $other = $box.find('.poll-option-box-other');
      var $next = $box.parents('.poll').find('button.next-btn');
      $box.on('click', '.poll-option-box', function () {
        $next.removeAttr('disabled');
        if ($(this).data('toggle')) {
          $other.removeClass('d-none');
        } else {
          $other.addClass('d-none');
          $other.find('textarea').val('');
        }
        $selected_option.val($(this).find('input').val());
      });
    });
    ratings.each(function () {
      var box = $(this);
      $('.star', box).each(function (idx) {
        $(this).click(function () {
          $(box).removeClass(['value-1', 'value-2', 'value-3', 'value-4', 'value-5']).addClass('value-' + (idx + 1));
          $('.label-value', box).text('' + (idx + 1) + ' / 5');
          $(box).parents('.poll').find('button.next-btn').removeAttr('disabled');
        });
      });
    });
    var $last_poll_slide = $('#poll-box .poll-slide').last();
    $last_poll_slide.find('.next-btn').click(function () {
      var data = {
        value: [],
        select: [],
        texts: []
      };
      $('.rating .label-value').each(function () {
        data.value.push(parseInt($(this).text().replace(' / 5')));
      });
      poll_options.each(function () {
        data.select.push($(this).find('.selected-option').val());
      });
      poll_texts.each(function () {
        data.texts.push($(this).find('textarea').val());
      });
      data = {
        value: JSON.stringify(data.value),
        select: JSON.stringify(data.select),
        texts: JSON.stringify(data.texts),
        other: $('.poll-option-box-other textarea').val()
      };
      console.log('risultato survey', data);

      //poll_select.select2('destroy');

      poll_sending.removeClass('d-none');
      $.ajax({
        type: "POST",
        async: true,
        url: window.survey_url,
        cache: false,
        data: data
      }).done(function () {
        setTimeout(function () {
          poll_sending.addClass('d-none');
          poll_outro.removeClass('d-none');
        }, 1500);
      }).fail(function (response) {
        console.error(response);
      });
    });
    if ($survey.hasClass('start')) {
      window.startPoll();
    }
  };
  window.initSurvey = initSurvey;
  window.showSurvey = showSurvey;
  window.startSurvey = startSurvey;
  $(function () {
    $responsive_container = $('.responsive-container');
    surveyStatus = initSurvey();
    if (surveyStatus && $survey.hasClass('force-survey')) {
      showSurvey();
    }

    // TODO: unifica survey pmi con default :)

    if (!$survey.length) {
      initPollBoxSurvey();
    }
  });
});